const { Client } = require('@notionhq/client');
console.log(process.env);
console.log("NOTION_API_KEY : " + process.env.NOTION_API_KEY);
// const notion = new Client({ auth: process.env.NOTION_API_KEY });
const notion = new Client({ auth: "secret_apO2120NYXWqTQZyD4HKGYTIp4CdFfZt7uirWlR3i7S" });

(async () => {
  const pageId = '10b69d432f0a8045b4dfc0c777e0d952';
  const response = await notion.pages.retrieve({ page_id: pageId });
  console.log(response);
})();


