import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";

import App from "./App";
import Test from "./Test";
import Notion from "./Notion";

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

const test = createRoot(document.getElementById("test"));
test.render(
  <StrictMode>
    <Test />
  </StrictMode>
);


